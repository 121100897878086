<template>
  <v-container style="height: 100%">
    <p v-if="$route.params.errorMessage" class="err-msg">{{ $route.params.errorMessage }}</p>
    <v-row justify="center" align-content="center" style="height: 100%">
      <v-col cols="12" sm="6" md="4">
        <h2 class="font-weight-medium mb-5">Увійти</h2>
        <form class="form-group">
          <PhoneEdit
            :value="phone"
            :is-touched="isPhoneTouched"
            label="Телефон"
            validate
            outlined
            dense
            @change="(val) => (phone = val)"
            @validation="(val) => (isPhoneValid = val)"
          />
          <v-text-field
            v-model="password"
            @keyup.enter="submit"
            :error-messages="passwordErrors"
            label="Пароль"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            outlined
            dense
          ></v-text-field>

          <v-btn class="mr-4" color="success" @click="submit"> Увійти </v-btn>
          <v-btn color="error" @click="clear"> Очистити </v-btn>

          <div class="registration mt-5">
            <router-link :to="`${ROUTER_LINKS.RESTORE_PASSWORD}`">Забули пароль?</router-link>
            <router-link :to="`${ROUTER_LINKS.REGISTRATION}`">Зареєструватися</router-link>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapMutations } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import PhoneEdit from '@/components/common/PhoneEdit.vue'

export default {
  name: 'Login',

  components: { PhoneEdit },

  mixins: [validationMixin],

  validations: {
    password: { required },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    phone: '',
    password: '',
    showPassword: false,
    isPhoneValid: false,
    isPhoneTouched: false,
  }),

  computed: {
    ...mapState('auth', ['credentials']),

    currentPhone() {
      return this.$route.params.phone || ''
    },
    hasErr() {
      return this.$v.$anyError || !this.isPhoneValid
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required')
      return errors
    },
  },

  mounted() {
    this.setUserPhone()
  },

  beforeDestroy() {
    this.SET_CREDENTIALS(null)
  },

  methods: {
    ...mapActions('auth', ['loginUser']),
    ...mapActions('init', ['initState']),
    ...mapMutations('auth', ['SET_CREDENTIALS']),

    setUserPhone() {
      if (this.currentPhone) this.phone = this.currentPhone
    },
    async submit() {
      this.$v.$touch()
      this.isPhoneTouched = true
      if (this.hasErr) return null
      let payload = {
        login: this.phone,
        password: this.password,
      }
      try {
        await this.loginUser(payload)
        if (!this.credentials) return
        let { access_token, refresh_token } = this.credentials
        window.localStorage.setItem('accessToken', access_token)
        window.localStorage.setItem('refreshToken', refresh_token)
        await this.initState()
        await this.$router.replace({ name: 'Home' })
      } catch (e) {
        console.error(e)
      }
    },
    clear() {
      this.$v.$reset()
      this.isPhoneTouched = false
      this.phone = ''
      this.password = ''
    },
  },
}
</script>

<style scoped lang="scss">
.registration {
  display: flex;
  justify-content: space-between;
}
.err-msg {
  color: red;
  font-weight: bold;
  text-align: center;
}
</style>
